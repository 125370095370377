<template>
  <div class="carousel-parent">
    <Carousel
      class="carousel"
      :perPage="1"
      :loop="true"
      :autoplay="true"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :navigationNextLabel="nextLabelSvg"
      :navigationPrevLabel="prevLabelSvg"
      :autoplayTimeout="5000"
    >
      <!-- <Slide
        class="carousel__slide"
        v-for="(item, index) in slides"
        :key="index"
      >
        <img :src="item.image" class="carousel__image" alt="Illustrasjoner" />

        <div class="carousel__text">
          <h3>{{ item.text }}</h3>
          <p>{{ item.author }}</p>
        </div>
      </Slide> -->

      <Slide class="carousel__slide">
        <img
          :src="carouselImage1"
          class="carousel__image"
          alt="Illustrasjoner"
        />

        <div class="carousel__text">
          <h3>{{ carouselSlideOneTitle }}</h3>
          <p>{{ carouselSlideOneSubtitle }}</p>
        </div>
      </Slide>

      <Slide class="carousel__slide">
        <img
          :src="carouselImage2"
          class="carousel__image"
          alt="Illustrasjoner"
        />

        <div class="carousel__text">
          <h3>{{ carouselSlideTwoTitle }}</h3>
          <p>{{ carouselSlideTwoSubtitle }}</p>
        </div>
      </Slide>

      <Slide class="carousel__slide">
        <img
          :src="carouselImage3"
          class="carousel__image"
          alt="Illustrasjoner"
        />

        <div class="carousel__text">
          <h3>{{ carouselSlideThreeTitle }}</h3>
          <p>{{ carouselSlideThreeSubtitle }}</p>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Slide, Carousel } from "vue-carousel";

export default {
  data() {
    return {
      nextLabelSvg: `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="22.5" cy="22.5" r="22.5" fill="#D64431"/><path d="M30.8168 24.2031L23.9418 30.7656C23.5512 31.1172 22.9652 31.1172 22.6137 30.7266C22.2621 30.3359 22.2621 29.75 22.6527 29.3984L27.8481 24.4375H14.5668C14.0199 24.4375 13.6293 24.0469 13.6293 23.5C13.6293 22.9922 14.0199 22.5625 14.5668 22.5625H27.8481L22.6527 17.6406C22.2621 17.2891 22.2621 16.6641 22.6137 16.3125C22.9652 15.9219 23.5902 15.9219 23.9418 16.2734L30.8168 22.8359C31.0121 23.0312 31.1293 23.2656 31.1293 23.5C31.1293 23.7734 31.0121 24.0078 30.8168 24.2031Z" fill="#FFFDF8"/></svg>`,
      prevLabelSvg: `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="22.5" cy="22.5" r="22.5" transform="rotate(180 22.5 22.5)" fill="#D64431"/><path d="M14.1832 20.7969L21.0582 14.2344C21.4488 13.8828 22.0348 13.8828 22.3863 14.2734C22.7379 14.6641 22.7379 15.25 22.3473 15.6016L17.1519 20.5625L30.4332 20.5625C30.9801 20.5625 31.3707 20.9531 31.3707 21.5C31.3707 22.0078 30.9801 22.4375 30.4332 22.4375L17.1519 22.4375L22.3473 27.3594C22.7379 27.7109 22.7379 28.3359 22.3863 28.6875C22.0348 29.0781 21.4098 29.0781 21.0582 28.7266L14.1832 22.1641C13.9879 21.9688 13.8707 21.7344 13.8707 21.5C13.8707 21.2266 13.9879 20.9922 14.1832 20.7969Z" fill="#FFFDF8"/></svg>`,
      slides: [
        {
          text: "Alt jeg får av Matvarehuset er så godt, så jeg er fornøyd.",
          author: "- Ove, senior",
          image: "/assets/smaken.png",
        },
        {
          text:
            "De som leverer smiler alltid, er hyggelige og tøyser litt når de kommer.",
          author: "- Ove, senior",
          image: "/assets/leveranse.png",
        },
        {
          text: "Vi er bare veldig fornøyd med servicen",
          author: "- Line, datter",
          image: "/assets/service.png",
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
  },
  props: {
    carouselSlideOneTitle: String,
    carouselSlideOneSubtitle: String,
    carouselSlideTwoTitle: String,
    carouselSlideTwoSubtitle: String,
    carouselSlideThreeTitle: String,
    carouselSlideThreeSubtitle: String,
    carouselImage1: String,
    carouselImage2: String,
    carouselImage3: String,
  },
};
</script>

<style lang="scss">
.carousel-parent {
  background-color: var(--secondary-contrast);
  padding: 5rem 10rem;
  position: relative;

  @media (max-width: $tablet) {
    padding: 4rem 5rem;
  }

  @media (max-width: $mobile) {
    padding: 2rem 0rem 6rem 0rem;
  }
}

.carousel {
  width: 100%;
  max-width: 1250px;
  margin: auto;

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__image {
    width: 50%;
    border-radius: 20px;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}

.VueCarousel-navigation {
  @media (max-width: $mobile) {
    position: relative;
  }
}

.VueCarousel-navigation-button {
  &:focus {
    outline: none !important;
  }

  @media (max-width: $mobile) {
    position: absolute;
    top: 100px;
  }
}

.VueCarousel-navigation-prev {
  @media (max-width: $mobile) {
    left: 100px !important;
    margin-top: 40px;
  }
}

.VueCarousel-navigation-next {
  @media (max-width: $mobile) {
    right: 100px !important;
    margin-top: 40px;
  }
}
</style>
