const Clone = function() {
  return JSON.parse(JSON.stringify.apply(this, arguments));
};

function ScrollToAnchor(value) {
  let target = document.querySelector(value);
  if (!target) return;
  target.scrollIntoView({ behavior: "smooth" });
}

const redirect = (name, options, instance) => {
  return async function() {
    name = name instanceof Array ? name : [name];

    let { action, scrollTo, hash, params, query } = options;

    if (
      !name.includes(instance.$route.name) ||
      params?.slug != instance.$route.query?.slug
    )
      await instance.$router.push({ name: name[0], hash, params, query });

    if (hash) {
      setTimeout(() => {
        return ScrollToAnchor(hash);
      }, 500);
    }
    if (action) return instance.action();
    if (scrollTo !== undefined) window.scrollTo(0, scrollTo);
  };
};

function redirectMatvarehuset() {
  const externalURL = "https://matvarehuset.no/";
  window.location.href = externalURL;
}

function redirectKontakt() {
  const externalURL = "https://contactus.mdlzapps.com/freiano/contact/no-NO/";
  window.location.href = externalURL;
}

export {
  Clone,
  ScrollToAnchor,
  redirect,
  redirectMatvarehuset,
  redirectKontakt,
};
