<template>
  <div class="hero-section">
    <div class="hero-section__text-section">
      <h1>Takk for din registrering.</h1>
      <p>
        Vi vil kontakte deg snarest mulig, og senest innen to virkedager.
      </p>

      <Orangebutton
        label="Hjem"
        :destination="{ path: 'home' }"
        class="hero-section__button"
      />
    </div>

    <img
      class="hero-section__image"
      src="@/assets/elements/hero_desktop.png"
      alt="Matrett bakgrunn"
    />
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import Orangebutton from "@/components/Orangebutton.vue";

export default {
  components: {
    ButtonComponent,
    Orangebutton,
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 2100,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.hero-section {
  //   overflow: hidden;

  //   display: flex;
  //   align-items: center;

  //   @media (max-width: $tablet) {
  //     flex-direction: column;
  //     gap: 3.125rem;
  //   }

  //   &__text-section {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: start;

  //     margin-left: 10%;

  //     p {
  //       margin-top: 1rem;

  //       @media (min-width: $tablet) {
  //         font-size: clamp(0.75rem, 1.7vw, 1.5rem);
  //       }
  //     }

  //     @media (max-width: $tablet) {
  //       margin: 0;
  //       order: 2;
  //       padding: 0rem 1.18rem;
  //       align-items: center;
  //     }
  //   }

  //   &__text-section > * {
  //     text-align: start;
  //   }

  //   &__image {
  //     position: relative;
  //     width: 50%;
  //     top: -100px;
  //     right: -175px;

  //     @media (max-width: $tablet) {
  //       width: 631px;
  //       height: 634px;
  //       top: 50px;
  //       right: -250px;

  //       order: 1;
  //     }

  //     @media (max-width: $mobile) {
  //       width: 631px;
  //       height: 634px;
  //       top: -32px;
  //       right: -150px;

  //       order: 1;
  //     }
  //   }

  &__button {
    margin-top: 3rem;

    @media (max-width: $tablet) {
      margin-bottom: 2rem;
    }
  }
}
</style>
