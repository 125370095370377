<template>
  <div class="faq">
    <h1>{{ faqTitle }}</h1>

    <p>
      Her har vi samlet spørsmål og svar vedrørende det folk flest lurer på.
    </p>

    <div class="dropdown-parent">
      <Dropdown
        v-for="(item, index) in faqItems"
        :title="item.title"
        :subtitle="item.subtitle"
        :index="index"
      />
    </div>

    <Tryfree
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
    />

    <OrangeButton
      label="Hjem"
      :destination="{ path: 'home' }"
      class="faq__button-parent"
    />
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Tryfree from "@/components/Tryfree.vue";
import OrangeButton from "@/components/Orangebutton.vue";

export default {
  components: {
    Dropdown,
    Tryfree,
    OrangeButton,
  },
  props: {
    faqTitle: String,
    faqSubtitle: String,
    faq1Title: String,
    faq1Description: String,
    faq2Title: String,
    faq2Description: String,
    faq3Title: String,
    faq3Description: String,
    faq4Title: String,
    faq4Description: String,
    faq5Title: String,
    faq5Description: String,
    faq6Title: String,
    faq6Description: String,
    faq7Title: String,
    faq7Description: String,
    faq8Title: String,
    faq8Description: String,
    faq9Title: String,
    faq9Description: String,
    faq10Title: String,
    faq10Description: String,
    faq11Title: String,
    faq11Description: String,
    faq12Title: String,
    faq12Description: String,
    faq13Title: String,
    faq13Description: String,
    faq14Title: String,
    faq14Description: String,
    faq15Title: String,
    faq15Description: String,
    faq16Title: String,
    faq16Description: String,
    faq17Title: String,
    faq17Description: String,
    faq18Title: String,
    faq18Description: String,
    faq19Title: String,
    faq19Description: String,
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
  },
  computed: {
    faqItems() {
      return [
        {
          title: this.faq1Title,
          subtitle: this.faq1Description,
        },
        {
          title: this.faq2Title,
          subtitle: this.faq2Description,
        },
        {
          title: this.faq3Title,
          subtitle: this.faq3Description,
        },
        {
          title: this.faq4Title,
          subtitle: this.faq4Description,
        },
        {
          title: this.faq5Title,
          subtitle: this.faq5Description,
        },
        {
          title: this.faq6Title,
          subtitle: this.faq6Description,
        },
        {
          title: this.faq7Title,
          subtitle: this.faq7Description,
        },
        {
          title: this.faq8Title,
          subtitle: this.faq8Description,
        },
        {
          title: this.faq9Title,
          subtitle: this.faq9Description,
        },
        {
          title: this.faq10Title,
          subtitle: this.faq10Description,
        },
        {
          title: this.faq11Title,
          subtitle: this.faq11Description,
        },
        {
          title: this.faq12Title,
          subtitle: this.faq12Description,
        },
        {
          title: this.faq13Title,
          subtitle: this.faq13Description,
        },
        {
          title: this.faq14Title,
          subtitle: this.faq14Description,
        },
        {
          title: this.faq15Title,
          subtitle: this.faq15Description,
        },
        {
          title: this.faq16Title,
          subtitle: this.faq16Description,
        },
        {
          title: this.faq17Title,
          subtitle: this.faq17Description,
        },
        {
          title: this.faq18Title,
          subtitle: this.faq18Description,
        },
        {
          title: this.faq19Title,
          subtitle: this.faq19Description,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.faq {
  width: 100%;
  max-width: 1100px;
  min-height: 100vh;
  padding: 0rem 1.18rem;
  padding-top: 12.5rem;
  margin: auto;
  text-align: start;

  @media (max-width: $mobile) {
    padding-top: 7.5rem;
  }

  p {
    margin: 1rem 0rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  &__button-parent {
    margin-bottom: 5rem;
  }
}

.dropdown-parent {
  margin-top: 2rem;
}
</style>
