<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h1>Åpenhetsloven</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                Åpenhetsloven trådte i kraft 1. juli 2022
              </span>
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Loven pålegger større virksomheter, som Matvarehuset AS, å
              gjennomføre tiltak knyttet til grunnleggende menneskerettigheter
              og anstendige arbeidsforhold. Tiltakene gjelder så vel egen
              virksomhet som leverandører, og er av tre slag:
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              1. Vi skal gjennomføre aktsomhetsvurderinger. Det betyr at vi
              kartlegger og vurderer virksomheten vår opp imot negativ
              påvirkning på menneskerettigheter og arbeidsforhold. Dersom vi
              finner slik negativ påvirkning, skal vi forebygge, redusere eller
              stanse den negative påvirkningen.

              <br />
              <br />

              2. Vi skal publisere en årlig redegjørelse for dette arbeidet. Det
              skal skje innen 30. juni hvert år, første gang i 2023.

              <br />
              <br />

              3. Vi skal svare på spørsmål fra allmennheten om
              aktsomhetsvurderingene vi har gjort.
            </p>

            <div class="terms-container__flex">
              <a href="/assets/retningslinjer.doc" download
                >Retningslinjer etisk handel for leverandører</a
              >

              <a
                href="https://matvarehuset.no/wp-content/uploads/2024/06/Apenhetsloven-rapport-2023-signert-.pdf"
                target="_blank"
                >Rapport - Åpenhetsloven 2023</a
              >
            </div>
          </div>
        </div>

        <Orangebutton
          label="Hjem"
          :destination="{ path: 'home' }"
          class="terms-container__button-parent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Orangebutton from "@/components/Orangebutton.vue";

export default {
  components: {
    Orangebutton,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  text-align: center;

  padding: 200px;

  &__flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 85px;
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 1.125rem;
      }
    }
  }

  h2 {
    margin-top: 16px;
  }

  p {
    line-height: 1.6rem;
  }

  &__button-parent {
    display: flex;
    justify-content: start;
    margin-top: 5rem;

    @media (max-width: $tablet) {
      justify-content: center;
    }
  }

  span {
    font-weight: 1000;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }

  a {
    color: #000000;
  }
}
</style>
