<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h1>Cookies</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                Vår bruk av informasjonskapsler (cookies)
              </span>
            </p>
          </div>

          <br />

          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                Vi bruker ikke informasjonskapsler (cookies) ved bruk av
                <a href="matvarehusethjem.no/" target="_blank"
                  >matvarehusethjem.no</a
                >
              </span>
            </p>
          </div>
        </div>

        <!-- <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                Cookies informasjon til nettsiden Matvarehusethjem.no.
              </span>
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Du kontrollerer dine egne data</span><br />

              Vi og våre forretningspartnere bruker teknologier, inkludert
              informasjonskapsler/«cookies» til å samle informasjon om deg for
              forskjellige formål, inkludert: Statistiske

              <br />
              <br />

              Ved å trykke «Godta» gir du din tillatelse til alle disse
              formålene. Du kan også velge formålet du vil samtykke til ved å
              klikke på avmerkingsboksene under formålet, og deretter trykke
              «Lagre innstillingene».

              <br />
              <br />

              Du kan trekke tilbake samtykket ditt til enhver tid ved å trykke
              på et lille ikonet i nederste venstre hjørne av nettsiden.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Nødvendige</span><br />

              Nødvendige informasjonskapsler hjelper med å gjøre en hjemmeside
              brukbar ved å aktivere grunnleggende funksjoner, slik som
              side-navigasjon og tilgang til sikre områder av hjemmesiden.
              Hjemmesiden kan ikke fungere optimalt uten disse
              informasjonskapslene.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Statistiske</span>

              <br />
              Statistiske informasjonskapsler hjelper hjemmesidens eier med å
              forstå hvordan besøkende samhandler med hjemmesiden ved å samle
              inn og rapportere opplysninger.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Retningslinjer for informasjonskapsler</span>

              <br />
              Samtykket ditt gjelder følgende nettsteder: matvarehusethjem.no
              Disse retningslinjene for informasjonskapsler (cookies) ble
              oppdatert 14.08.2024
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Hva er en informasjonskapsel?</span>

              <br />
              En informasjonskapsel er en liten fil som lagres på datamaskinen,
              nettbrettet eller smarttelefonen din. En informasjonskapsel er
              ikke et program som inneholder skadelig prosesser eller virus.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Slik bruker nettsiden informasjonskapsler</span>

              <br />

              Enkelte informasjonskapsler utfører essensielle funksjoner for
              nettsiden. Informasjonskapsler hjelper oss også til å forstå
              hvorfor du besøker nettsiden, slik at vi kontinuerlig kan optimere
              og tilpasse nettsiden til dine spesifikke behov og interesser.
              Informasjonskapsler husker ting som varene du har lagt i
              handlekurven, om du har besøkt nettsiden tidligere, om du er
              logget inn og hvilket språk og valuta du foretrekker å se på
              nettsiden. Vi bruker også informasjonskapsler til å rette
              annonsene våre mot deg på andre nettsider. Generelt sett bruker vi
              informasjonskapsler som en del av våre tjenester, og for å kunne
              presentere deg for innhold som er så relevant som mulig for deg.

              <br />
              <br />

              Du kan se de spesifikke tjenestene som lagrer informasjonskapsler,
              og hvorfor de lagres under de forskjellige kategoriene:
              Statistiske
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Hvor lenge lagres informasjonskapsler?</span>

              <br />

              Hvor lenge en informasjonskapsel blir lagret på enheten, og i
              nettleserne varierer. Levetiden til en informasjonskapsel beregnes
              ut fra det siste besøket du hadde på nettsiden. Når levetiden til
              en informasjonskapsel er over, slettes den automatisk. Levetiden
              til alle informasjonskapslene vi benytter er spesifisert i
              dokumentet Retningslinjer for informasjonskapsler.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Avvise eller slette informasjonskapsler</span>

              <br />
              Du kan avvise alle informasjonskapsler, og/eller
              informasjonskapsler fra tredjepart helt og holdent ved å endre
              innstillingene i nettleseren på datamaskinen, nettbrettet eller
              smarttelefonen din. Plasseringen av disse innstillingene avhenger
              av hvilken nettleser du benytter. Men du må være klar over at hvis
              du avviser alle og/eller tredjepartskapsler, vil det være
              funksjoner og tjenester som du ikke lenger kan bruke på nettsidene
              (siden disse er avhengige av informasjonskapsler).
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Hvordan kan jeg slette informasjonskapsler?</span>

              <br />
              Det er enkelt å slette informasjonskapsler som du har godtatt
              tidligere. Sletteprosessen avhenger av hvilken nettleser du bruker
              (Chrome, Firefox, Safari m.m.), og enheten du bruker
              (smarttelefon, nettbrett, Windows-PC, Mac).
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Endre ditt samtykke</span>

              <br />
              Du kan endre samtykket ditt ved å enten slette
              informasjonskapslene fra nettleseren din. Husk: Hvis du brukere
              flere nettlesere, må du slette informasjonskapslene fra alle.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Har du spørsmål?</span>

              <br />
              Hvis du har kommentarer eller spørsmål relatert til informasjonen,
              og/eller behandling av personopplysninger kan du kontakte oss.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Kontakt oss på:</span><br />
              <span>Telefon: +47 400 06 499</span><br />
              <span>E-post: firmapost@matvarehuset.no</span><br />
            </p>
          </div>
        </div> -->

        <Orangebutton
          label="Hjem"
          :destination="{ path: 'home' }"
          class="terms-container__button-parent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import Orangebutton from "@/components/Orangebutton.vue";

export default {
  components: {
    ButtonComponent,
    Orangebutton,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  text-align: center;

  padding: 200px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 85px;
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 1.125rem;
      }
    }
  }

  h2 {
    margin-top: 16px;
  }

  p {
    line-height: 1.6rem;
  }

  &__button-parent {
    display: flex;
    justify-content: start;
    margin-top: 5rem;

    @media (max-width: $tablet) {
      justify-content: center;
    }
  }

  span {
    font-weight: 1000;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }
}
</style>
