<template>
  <LegalSection />
</template>

<script>
import LegalSection from "@/components/LegalSection.vue";

export default {
  components: {
    LegalSection,
  },
};
</script>

<style lang="scss"></style>
