<template>
  <div class="tryfree">
    <p class="tryfree__grey-text ">{{ registerPreText }}</p>

    <OrangeButton
      :label="registerTitle"
      :destination="{ path: 'home', hash: '#delta' }"
    />

    <p class="tryfree__grey-text">{{ registerPostText }}</p>
  </div>
</template>

<script>
import OrangeButton from "@/components/Orangebutton.vue";

export default {
  components: { OrangeButton },
  props: {
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
  },
};
</script>

<style lang="scss">
.tryfree {
  margin-bottom: 4rem;

  &__grey-text {
    font-size: 1.06rem;
    color: #000000;
    margin: auto;
    text-align: center !important;
    font-weight: 500;
  }

  &__button-orange {
    display: block;
    margin: auto;
    background-color: var(--primary);
    padding: 0.75rem 3rem;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;

    span {
      font-size: 1.5rem;
      font-family: var(--primary-font);
      color: var(--secondary-contrast);
    }
  }
}
</style>
