<template>
  <div
    class="dropdown"
    @click="isExpanded = !isExpanded"
    :class="{ 'dropdown--grey': index % 2 === 0 }"
  >
    <div class="dropdown__flex">
      <h3>{{ title }}</h3>

      <p v-if="isExpanded">
        {{ subtitle }}
      </p>
    </div>

    <img
      src="@/assets/elements/vector-right.svg"
      v-if="!isExpanded"
      class="dropdown__vector"
      alt="Illustrasjon"
    />
    <img
      src="@/assets/elements/vector-down.svg"
      v-if="isExpanded"
      class="dropdown__vector"
      alt="Illustrasjon"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
    };
  },
  props: {
    title: String,
    subtitle: String,
    index: Number,
  },
};
</script>

<style lang="scss">
.dropdown {
  padding: 2rem 5rem;
  border-top: 1px solid;
  border-color: #000000;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: start;

  &__flex {
    display: flex;
    flex-direction: column;
  }

  p {
    margin-top: 1rem;
  }

  @media (max-width: $mobile) {
    padding: 1rem 0.5rem;
  }

  &--grey {
    background-color: #eae7dd;
  }

  &__vector {
    margin-right: 1rem;
    margin-top: 0.5rem;
  }
}
</style>
