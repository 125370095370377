<template>
  <div :class="{ 'tile-parent-highlight': highlight }">
    <div class="tile" :class="{ tile__highlight: highlight }">
      <p v-if="number" class="tile__number">
        {{ number }}
      </p>
      <p>{{ text }}</p>
      <p :class="{ tile__priceextramargin: !callout }" class="tile__price">
        {{ price }}
      </p>
      <div v-if="callout" class="tile__divider"></div>
      <p v-if="callout" class="tile__callout">{{ callout }}</p>
    </div>

    <div v-if="highlight" class="tile__popular">
      <p>{{ pricingTileCallout }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: String,
    text: String,
    price: String,
    callout: String,
    highlight: Boolean,
    pricingTileCallout: String,
  },
};
</script>

<style lang="scss">
// .parent {
//   width: 500px;
//   height: 500px;
//   background-color: red;
//   position: relative;
// }
// .child {
//   width: 250px;
//   height: 250px;
//   background-color: blue;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 1;
// }

.tile-parent-highlight {
  position: relative;
  top: -15px;

  width: 287px;
  height: 340px;
  border-radius: 25px;

  display: flex;
  justify-content: center;

  background-color: var(--tertiary);

  @media (max-width: 870px) {
    top: 0px;
  }
}

.tile {
  width: 247px;
  max-height: 260px;

  border-radius: 20px;
  padding: 1rem;
  background-color: var(--primary);

  &__highlight {
    position: absolute;
    height: 260px;

    top: 15px;
  }

  &__popular {
    position: absolute;
    bottom: 15px;

    p {
      font-weight: 900;
      font-family: "Newsom-Medium";
    }
  }

  p {
    color: var(--secondary-contrast);
  }

  &__number {
    font-size: 5rem;
    margin-left: 0.5rem;
    line-height: 90px;
    margin-bottom: -1rem;
  }

  &__price {
    font-size: 2.5rem;
    margin-top: 1.25rem;
  }

  &__priceextramargin {
    margin-top: 2.5rem;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: var(--secondary-contrast);
  }

  &__callout {
    margin-top: 0.9rem;
  }
}
</style>
