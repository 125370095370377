<template>
  <div class="orange-button-container">
    <button class="orange-button" @click="handleClick">
      <span>{{ label }}</span>
      <div class="orange-button__arrow-icon"></div>
    </button>
  </div>
</template>

<script>
import { redirect } from "@/utils";

export default {
  props: {
    label: String,
    destination: {
      type: Object,
    },
  },
  methods: {
    handleClick() {
      const redirectFunction = redirect(
        [this.destination.path],
        { hash: this.destination.hash },
        this
      );
      redirectFunction();
    },
  },
};
</script>

<style lang="scss">
.orange-button-container {
  a {
    text-decoration: none;
  }
}

.orange-button {
  margin: auto;
  background-color: var(--primary);
  padding: 1.1rem 3.5rem;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  outline: 2px solid transparent;
  outline-offset: -2px;

  span {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: var(--primary-font);
    color: var(--secondary-contrast);
    text-decoration: none !important;
  }

  &__arrow-icon {
    background-image: url("@/assets/elements/arrow-right.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 16px;
    height: 16px;
  }

  @media (max-width: $tablet) {
    padding: 1.25rem 1.5rem;
  }

  @media (min-width: $tablet) {
    &:hover {
      background-color: var(--secondary-contrast);
      outline-color: var(--primary);

      span {
        color: var(--primary);
      }

      .orange-button__arrow-icon {
        background-image: url("@/assets/elements/arrow-right-orange.svg");
      }
    }
  }
}
</style>
