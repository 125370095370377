<template>
  <div
    id="app"
    :class="[
      'route--' + view.toLowerCase(),
      isOnTop ? 'page--is-top' : 'page--is-scrolled',
    ]"
  >
    <!-- <div
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="0"
          :isOpen.sync="isExpanded"
          :action="redirectMatvarehuset"
        />
      </div>
    </div> -->

    <Navbar :logo="logo" :navItems="navItems" />

    <transition mode="out-in" name="route">
      <div class="loading-screen" v-if="isLoading"><h1>Laster...</h1></div>

      <router-view
        v-if="!isLoading"
        :heroText="heroText"
        :heroTitle="heroTitle"
        :heroImage="heroImage"
        :pricingTitle="pricingTitle"
        :seeMoreButton="seeMoreButton"
        :seeLessButton="seeLessButton"
        :pricingTextOne="pricingTextOne"
        :pricingSubtitleOne="pricingSubtitleOne"
        :pricingSubtitleTwo="pricingSubtitleTwo"
        :pricingTextTwo="pricingTextTwo"
        :pricingSubtitleThree="pricingSubtitleThree"
        :pricingTextThree="pricingTextThree"
        :pricingTextFour="pricingTextFour"
        :pricingTileOneTitle="pricingTileOneTitle"
        :pricingTileOneSubtitle="pricingTileOneSubtitle"
        :pricingTileOnePrice="pricingTileOnePrice"
        :pricingTileOneDescription="pricingTileOneDescription"
        :pricingTileCallout="pricingTileCallout"
        :pricingTileTwoTitle="pricingTileTwoTitle"
        :pricingTileTwoSubtitle="pricingTileTwoSubtitle"
        :pricingTileTwoPrice="pricingTileTwoPrice"
        :pricingTileTwoDescription="pricingTileTwoDescription"
        :pricingTileThreeTitle="pricingTileThreeTitle"
        :pricingTileThreeSubtitle="pricingTileThreeSubtitle"
        :pricingTileThreePrice="pricingTileThreePrice"
        :pricingTileThreeDescription="pricingTileThreeDescription"
        :pricingTileFourTitle="pricingTileFourTitle"
        :pricingTileFourPrice="pricingTileFourPrice"
        :pricingTileFiveTitle="pricingTileFiveTitle"
        :pricingTileFivePrice="pricingTileFivePrice"
        :pricingTileSixTitle="pricingTileSixTitle"
        :pricingTileSixPrice="pricingTileSixPrice"
        :pricingTileSixDescription="pricingTileSixDescription"
        :pricingTileSevenTitle="pricingTileSevenTitle"
        :pricingTileSevenPrice="pricingTileSevenPrice"
        :pricingTileSevenDescription="pricingTileSevenDescription"
        :pricingTileEightTitle="pricingTileEightTitle"
        :pricingTileEightPrice="pricingTileEightPrice"
        :pricingTileEightDescription="pricingTileEightDescription"
        :pricingTileNineTitle="pricingTileNineTitle"
        :pricingTileNinePrice="pricingTileNinePrice"
        :pricingTileTenTitle="pricingTileTenTitle"
        :pricingTileTenPrice="pricingTileTenPrice"
        :pricingTileElevenTitle="pricingTileElevenTitle"
        :pricingTileElevenPrice="pricingTileElevenPrice"
        :foodImage1="foodImage1"
        :foodImage2="foodImage2"
        :registerPreText="registerPreText"
        :registerTitle="registerTitle"
        :registerPostText="registerPostText"
        :carouselSlideOneTitle="carouselSlideOneTitle"
        :carouselSlideOneSubtitle="carouselSlideOneSubtitle"
        :carouselSlideTwoTitle="carouselSlideTwoTitle"
        :carouselSlideTwoSubtitle="carouselSlideTwoSubtitle"
        :carouselSlideThreeTitle="carouselSlideThreeTitle"
        :carouselSlideThreeSubtitle="carouselSlideThreeSubtitle"
        :carouselImage1="carouselImage1"
        :carouselImage2="carouselImage2"
        :carouselImage3="carouselImage3"
        :benefitTitle="benefitTitle"
        :benefit1="benefit1"
        :benefit2="benefit2"
        :benefit3="benefit3"
        :benefit4="benefit4"
        :benefit5="benefit5"
        :benefit6="benefit6"
        :benefit7="benefit7"
        :benefit8="benefit8"
        :aboutTitle="aboutTitle"
        :aboutSection1Title="aboutSection1Title"
        :aboutSection1Text="aboutSection1Text"
        :aboutSection2Title="aboutSection2Title"
        :aboutSection2Text="aboutSection2Text"
        :aboutSection3Title="aboutSection3Title"
        :aboutSection3Text="aboutSection3Text"
        :aboutSection4Title="aboutSection4Title"
        :aboutSection4Text="aboutSection4Text"
        :aboutImage1="aboutImage1"
        :aboutImage2="aboutImage2"
        :aboutImage3="aboutImage3"
        :aboutImage4="aboutImage4"
        :faqLinkTitle="faqLinkTitle"
        :faqLinkSubtitle="faqLinkSubtitle"
        :faqLinkButton="faqLinkButton"
        :faqTitle="faqTitle"
        :faqSubtitle="faqSubtitle"
        :faq1Title="faq1Title"
        :faq1Description="faq1Description"
        :faq2Title="faq2Title"
        :faq2Description="faq2Description"
        :faq3Title="faq3Title"
        :faq3Description="faq3Description"
        :faq4Title="faq4Title"
        :faq4Description="faq4Description"
        :faq5Title="faq5Title"
        :faq5Description="faq5Description"
        :faq6Title="faq6Title"
        :faq6Description="faq6Description"
        :faq7Title="faq7Title"
        :faq7Description="faq7Description"
        :faq8Title="faq8Title"
        :faq8Description="faq8Description"
        :faq9Title="faq9Title"
        :faq9Description="faq9Description"
        :faq10Title="faq10Title"
        :faq10Description="faq10Description"
        :faq11Title="faq11Title"
        :faq11Description="faq11Description"
        :faq12Title="faq12Title"
        :faq12Description="faq12Description"
        :faq13Title="faq13Title"
        :faq13Description="faq13Description"
        :faq14Title="faq14Title"
        :faq14Description="faq14Description"
        :faq15Title="faq15Title"
        :faq15Description="faq15Description"
        :faq16Title="faq16Title"
        :faq16Description="faq16Description"
        :faq17Title="faq17Title"
        :faq17Description="faq17Description"
        :faq18Title="faq18Title"
        :faq18Description="faq18Description"
        :faq19Title="faq19Title"
        :faq19Description="faq19Description"
        :formTitle="formTitle"
        :formText="formText"
        :formCallout="formCallout"
        :formInfo="formInfo"
        :formButton="formButton"
        :formContactInfo1="formContactInfo1"
        :formContactInfo2="formContactInfo2"
        :formContactInfo3="formContactInfo3"
        :carousel2="carousel2"
      />

      <div v-else>Loading...</div>
    </transition>
    <Footer
      :footerText1="footerText1"
      :footerText2="footerText2"
      :footerText3="footerText3"
      :footerText4="footerText4"
    />
  </div>
</template>

<script>
//  :action="redirect(['home'], { scrollTo: 0 }, this)"

import { NavigationComponent } from "vue-elder-navigation";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Logo from "@/assets/elements/logo.png";
import { redirect } from "@/utils";
import { redirectMatvarehuset } from "@/utils";
import { redirectKontakt } from "@/utils";
import { apiKey } from "@/datocms.js";

export default {
  data() {
    return {
      isLoading: true,
      isOnTop: true,
      isExpanded: false,
      navItems: [
        {
          label: null,
          action: redirect(["home"], { hash: "#prices" }, this),
        },
        {
          label: "test",
          action: redirect(["home"], { hash: "#carousel2" }, this),
        },
        {
          label: null,
          action: redirect(["home"], { hash: "#usps" }, this),
        },
        {
          label: null,
          action: redirect(["home"], { hash: "#aboutus" }, this),
        },
        {
          label: null,
          action: redirect(["faq"], { scrollTo: 0 }, this),
        },
        {
          label: null,
          action: redirect(["home"], { hash: "#delta" }, this),
        },
      ],
      heroTitle: null,
      heroText: null,
      heroImage: null,
      isExpanded: false,
      pricingTitle: null,
      seeMoreButton: null,
      seeLessButton: null,
      pricingTextOne: null,
      pricingSubtitleOne: null,
      pricingSubtitleTwo: null,
      pricingTextTwo: null,
      pricingSubtitleThree: null,
      pricingTextThree: null,
      pricingTextFour: null,
      pricingTileOneTitle: null,
      pricingTileOneSubtitle: null,
      pricingTileOnePrice: null,
      pricingTileOneDescription: null,
      pricingTileCallout: null,
      pricingTileTwoTitle: null,
      pricingTileTwoSubtitle: null,
      pricingTileTwoPrice: null,
      pricingTileTwoDescription: null,
      pricingTileThreeTitle: null,
      pricingTileThreeSubtitle: null,
      pricingTileThreePrice: null,
      pricingTileThreeDescription: null,
      pricingTileFourTitle: null,
      pricingTileFourPrice: null,
      pricingTileFiveTitle: null,
      pricingTileFivePrice: null,
      pricingTileSixTitle: null,
      pricingTileSixPrice: null,
      pricingTileSixDescription: null,
      pricingTileSevenTitle: null,
      pricingTileSevenPrice: null,
      pricingTileSevenDescription: null,
      pricingTileEightTitle: null,
      pricingTileEightPrice: null,
      pricingTileEightDescription: null,
      pricingTileNineTitle: null,
      pricingTileNinePrice: null,
      pricingTileTenTitle: null,
      pricingTileTenPrice: null,
      pricingTileElevenTitle: null,
      pricingTileElevenPrice: null,
      foodImage1: null,
      foodImage2: null,
      registerPreText: null,
      registerTitle: null,
      registerPostText: null,
      carouselSlideOneTitle: null,
      carouselSlideOneSubtitle: null,
      carouselSlideTwoTitle: null,
      carouselSlideTwoSubtitle: null,
      carouselSlideThreeTitle: null,
      carouselSlideThreeSubtitle: null,
      carouselImage1: null,
      carouselImage2: null,
      carouselImage3: null,
      benefitTitle: null,
      benefit1: null,
      benefit2: null,
      benefit3: null,
      benefit4: null,
      benefit5: null,
      benefit6: null,
      benefit7: null,
      benefit8: null,
      aboutTitle: null,
      aboutSection1Title: null,
      aboutSection1Text: null,
      aboutSection2Title: null,
      aboutSection2Text: null,
      aboutSection3Title: null,
      aboutSection3Text: null,
      aboutSection4Title: null,
      aboutSection4Text: null,
      aboutImage1: null,
      aboutImage2: null,
      aboutImage3: null,
      aboutImage4: null,
      faqLinkTitle: null,
      faqLinkSubtitle: null,
      faqLinkButton: null,
      faqTitle: null,
      faqSubtitle: null,
      faq1Title: null,
      faq1Description: null,
      faq2Title: null,
      faq2Description: null,
      faq3Title: null,
      faq3Description: null,
      faq4Title: null,
      faq4Description: null,
      faq5Title: null,
      faq5Description: null,
      faq6Title: null,
      faq6Description: null,
      faq7Title: null,
      faq7Description: null,
      faq8Title: null,
      faq8Description: null,
      faq9Title: null,
      faq9Description: null,
      faq10Title: null,
      faq10Description: null,
      faq11Title: null,
      faq11Description: null,
      faq12Title: null,
      faq12Description: null,
      faq13Title: null,
      faq13Description: null,
      faq14Title: null,
      faq14Description: null,
      faq15Title: null,
      faq15Description: null,
      faq16Title: null,
      faq16Description: null,
      faq17Title: null,
      faq17Description: null,
      faq18Title: null,
      faq18Description: null,
      faq19Title: null,
      faq19Description: null,
      formTitle: null,
      formText: null,
      formCallout: null,
      formInfo: null,
      formButton: null,
      formContactInfo1: null,
      formContactInfo2: null,
      formContactInfo3: null,
      footerText1: null,
      footerText2: null,
      footerText3: null,
      footerText4: null,
      carousel2: null,
    };
  },
  computed: {
    view() {
      return this.$route.name || "";
    },
    logo() {
      return Logo;
    },
    projectName() {
      return "Matvarehuset";
    },
    navTransition() {
      return this.view === "Campaign" ? 350 : 5;
    },
  },
  methods: {
    redirect,
    redirectMatvarehuset,
    redirectKontakt,
  },
  created() {
    fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        query: `
        query {
          navigation{
            navigationElement1,
            navigationElement2,
            navigationElement3,
            navigationElement4,
            navigationElement5,
            navigationElement6,
          }
          heroSection {
            heroTitle,
            heroText,
            heroImage {
              url
            }
          }
          pricing {
            pricingTitle,
            seeMoreButton,
            seeLessButton,
            pricingTextOne,
            pricingSubtitleOne,
            pricingSubtitleTwo,
            pricingTextTwo,
            pricingSubtitleThree,
            pricingTextThree,
            pricingTextFour
            pricingTileOneTitle,
            pricingTileOneSubtitle,
            pricingTileOnePrice,
            pricingTileOneDescription,
            pricingTileCallout,
            pricingTileTwoTitle,
            pricingTileTwoSubtitle,
            pricingTileTwoPrice,
            pricingTileTwoDescription,
            pricingTileThreeTitle,
            pricingTileThreeSubtitle,
            pricingTileThreePrice,
            pricingTileThreeDescription,
            pricingTileFourTitle,
            pricingTileFourPrice,
            pricingTileFiveTitle,
            pricingTileFivePrice,
            pricingTileSixTitle
            pricingTileSixPrice,
            pricingTileSixDescription,
            pricingTileSevenTitle,
            pricingTileSevenPrice,
            pricingTileSevenDescription,
            pricingTileEightTitle,
            pricingTileEightPrice,
            pricingTileEightDescription,
            pricingTileNineTitle,
            pricingTileNinePrice,
            pricingTileTenTitle,
            pricingTileTenPrice,
            pricingTileElevenTitle,
            pricingTileElevenPrice,
          }
          foodImage {
            image1 {
              url
            }
            image2 {
              url
            }
          }
          register {
            registerPreText,
            registerTitle,
            registerPostText
          }
          carousel {
            carouselSlideOneTitle,
            carouselSlideOneSubtitle,
            carouselSlideTwoTitle,
            carouselSlideTwoSubtitle,
            carouselSlideThreeTitle,
            carouselSlideThreeSubtitle,
            image1{
              url
            }
            image2{
              url
            }
            image3{
              url
            }
          }
          benefit {
          benefitTitle,
          benefit1,
          benefit2,
          benefit3,
          benefit4,
          benefit5,
          benefit6,
          benefit7,
          benefit8,
          }
          form {
          formTitle,
          formText,
          formCallout,
          formInfo,
          formButton,
          formContactInfo1,
          formContactInfo2,
          formContactInfo3,
          }
          about{
            aboutTitle,
            aboutSection1Title,
            aboutSection1Text,
            aboutSection2Title,
            aboutSection2Text,
            aboutSection3Title,
            aboutSection3Text,
            aboutSection4Title,
            aboutSection4Text,
            faqLinkTitle,
            faqLinkSubtitle,
            faqLinkButton,
            image1{
              url
            }
            image2{
              url
            }
            image3{
              url
            }
            image4{
              url
            }
          }
          faq{
            faqTitle,
            faqSubtitle,
            faq1Title,
            faq1Description,
            faq2Title,
            faq2Description,
            faq3Title,
            faq3Description,
            faq4Title,
            faq4Description,
            faq5Title,
            faq5Description,
            faq6Title,
            faq6Description,
            faq7Title,
            faq7Description,
            faq8Title,
            faq8Description,
            faq9Title,
            faq9Description,
            faq10Title,
            faq10Description,
            faq11Title,
            faq11Description,
            faq12Title,
            faq12Description,
            faq13Title,
            faq13Description,
            faq14Title,
            faq14Description,
            faq15Title,
            faq15Description,
            faq16Title,
            faq16Description,
            faq17Title,
            faq17Description,
            faq18Title,
            faq18Description,
            faq19Title,
            faq19Description,
          }
          footer{
            footerText1,
            footerText2,
            footerText3,
            footerText4,
          }
        carousel2 {
          carouselText
          carouselTitle
          categories {
            category
            carouselImages{
              url
              alt
            }
            }
          }
        }
      `,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.heroTitle = res.data.heroSection.heroTitle;
        this.heroText = res.data.heroSection.heroText;
        this.heroImage = res.data.heroSection.heroImage.url;
        this.pricingTitle = res.data.pricing.pricingTitle;
        this.seeMoreButton = res.data.pricing.seeMoreButton;
        this.seeLessButton = res.data.pricing.seeLessButton;
        this.pricingTextOne = res.data.pricing.pricingTextOne;
        this.pricingSubtitleOne = res.data.pricing.pricingSubtitleOne;
        this.pricingTextTwo = res.data.pricing.pricingTextTwo;
        this.pricingSubtitleThree = res.data.pricing.pricingSubtitleThree;
        this.pricingTextThree = res.data.pricing.pricingTextThree;
        this.pricingTextFour = res.data.pricing.pricingTextFour;
        this.pricingTileOneTitle = res.data.pricing.pricingTileOneTitle;
        this.pricingTileOneSubtitle = res.data.pricing.pricingTileOneSubtitle;
        this.pricingTileOnePrice = res.data.pricing.pricingTileOnePrice;
        this.pricingTileOneDescription =
          res.data.pricing.pricingTileOneDescription;
        this.pricingTileCallout = res.data.pricing.pricingTileCallout;
        this.pricingTileTwoTitle = res.data.pricing.pricingTileTwoTitle;
        this.pricingTileTwoSubtitle = res.data.pricing.pricingTileTwoSubtitle;
        this.pricingTileTwoPrice = res.data.pricing.pricingTileTwoPrice;
        this.pricingTileTwoDescription =
          res.data.pricing.pricingTileTwoDescription;
        this.pricingTileThreeTitle = res.data.pricing.pricingTileThreeTitle;
        this.pricingTileThreeSubtitle =
          res.data.pricing.pricingTileThreeSubtitle;
        this.pricingTileThreePrice = res.data.pricing.pricingTileThreePrice;
        this.pricingTileThreeDescription =
          res.data.pricing.pricingTileThreeDescription;
        this.pricingTileFourTitle = res.data.pricing.pricingTileFourTitle;
        this.pricingTileFourPrice = res.data.pricing.pricingTileFourPrice;
        this.pricingTileFiveTitle = res.data.pricing.pricingTileFiveTitle;
        this.pricingTileFivePrice = res.data.pricing.pricingTileFivePrice;

        this.pricingTileSixTitle = res.data.pricing.pricingTileSixTitle;
        this.pricingTileSixPrice = res.data.pricing.pricingTileSixPrice;
        this.pricingTileSixDescription =
          res.data.pricing.pricingTileSixDescription;
        this.pricingTileSevenTitle = res.data.pricing.pricingTileSevenTitle;
        this.pricingTileSevenPrice = res.data.pricing.pricingTileSevenPrice;
        this.pricingTileSevenDescription =
          res.data.pricing.pricingTileSevenDescription;
        this.pricingTileEightTitle = res.data.pricing.pricingTileEightTitle;
        this.pricingTileEightPrice = res.data.pricing.pricingTileEightPrice;
        this.pricingTileEightDescription =
          res.data.pricing.pricingTileEightDescription;

        this.pricingTileNineTitle = res.data.pricing.pricingTileNineTitle;
        this.pricingTileNinePrice = res.data.pricing.pricingTileNinePrice;
        this.pricingTileTenTitle = res.data.pricing.pricingTileTenTitle;
        this.pricingTileTenPrice = res.data.pricing.pricingTileTenPrice;
        this.pricingTileElevenTitle = res.data.pricing.pricingTileElevenTitle;
        this.pricingTileElevenPrice = res.data.pricing.pricingTileElevenPrice;
        this.foodImage1 = res.data.foodImage.image1.url;
        this.foodImage2 = res.data.foodImage.image2.url;
        this.registerPreText = res.data.register.registerPreText;
        this.registerTitle = res.data.register.registerTitle;
        this.registerPostText = res.data.register.registerPostText;
        this.carouselSlideOneTitle = res.data.carousel.carouselSlideOneTitle;
        this.carouselSlideOneSubtitle =
          res.data.carousel.carouselSlideOneSubtitle;
        this.carouselSlideTwoTitle = res.data.carousel.carouselSlideTwoTitle;
        this.carouselSlideTwoSubtitle =
          res.data.carousel.carouselSlideTwoSubtitle;
        this.carouselSlideThreeTitle =
          res.data.carousel.carouselSlideThreeTitle;
        this.carouselSlideThreeSubtitle =
          res.data.carousel.carouselSlideThreeSubtitle;

        this.carouselImage1 = res.data.carousel.image1.url;
        this.carouselImage2 = res.data.carousel.image2.url;
        this.carouselImage3 = res.data.carousel.image3.url;

        this.benefitTitle = res.data.benefit.benefitTitle;
        this.benefit1 = res.data.benefit.benefit1;
        this.benefit2 = res.data.benefit.benefit2;
        this.benefit3 = res.data.benefit.benefit3;
        this.benefit4 = res.data.benefit.benefit4;
        this.benefit5 = res.data.benefit.benefit5;
        this.benefit6 = res.data.benefit.benefit6;
        this.benefit7 = res.data.benefit.benefit7;
        this.benefit8 = res.data.benefit.benefit8;
        this.aboutTitle = res.data.about.aboutTitle;
        this.aboutSection1Title = res.data.about.aboutSection1Title;
        this.aboutSection1Text = res.data.about.aboutSection1Text;
        this.aboutSection2Title = res.data.about.aboutSection2Title;
        this.aboutSection2Text = res.data.about.aboutSection2Text;
        this.aboutSection3Title = res.data.about.aboutSection3Title;
        this.aboutSection3Text = res.data.about.aboutSection3Text;
        this.aboutSection4Title = res.data.about.aboutSection4Title;
        this.aboutSection4Text = res.data.about.aboutSection4Text;
        this.aboutImage1 = res.data.about.image1.url;
        this.aboutImage2 = res.data.about.image2.url;
        this.aboutImage3 = res.data.about.image3.url;
        this.aboutImage4 = res.data.about.image4.url;
        this.faqLinkTitle = res.data.about.faqLinkTitle;
        this.faqLinkSubtitle = res.data.about.faqLinkSubtitle;
        this.faqLinkButton = res.data.about.faqLinkButton;
        this.faqTitle = res.data.faq.faqTitle;
        this.faqSubtitle = res.data.faq.faqSubtitle;
        this.faq1Title = res.data.faq.faq1Title;
        this.faq1Description = res.data.faq.faq1Description;
        this.faq2Title = res.data.faq.faq2Title;
        this.faq2Description = res.data.faq.faq2Description;
        this.faq3Title = res.data.faq.faq3Title;
        this.faq3Description = res.data.faq.faq3Description;
        this.faq4Title = res.data.faq.faq4Title;
        this.faq4Description = res.data.faq.faq4Description;
        this.faq5Title = res.data.faq.faq5Title;
        this.faq5Description = res.data.faq.faq5Description;
        this.faq6Title = res.data.faq.faq6Title;
        this.faq6Description = res.data.faq.faq6Description;
        this.faq7Title = res.data.faq.faq7Title;
        this.faq7Description = res.data.faq.faq7Description;
        this.faq8Title = res.data.faq.faq8Title;
        this.faq8Description = res.data.faq.faq8Description;
        this.faq9Title = res.data.faq.faq9Title;
        this.faq9Description = res.data.faq.faq9Description;
        this.faq10Title = res.data.faq.faq10Title;
        this.faq10Description = res.data.faq.faq10Description;
        this.faq11Title = res.data.faq.faq11Title;
        this.faq11Description = res.data.faq.faq11Description;
        this.faq12Title = res.data.faq.faq12Title;
        this.faq12Description = res.data.faq.faq12Description;
        this.faq13Title = res.data.faq.faq13Title;
        this.faq13Description = res.data.faq.faq13Description;
        this.faq14Title = res.data.faq.faq14Title;
        this.faq14Description = res.data.faq.faq14Description;
        this.faq15Title = res.data.faq.faq15Title;
        this.faq15Description = res.data.faq.faq15Description;
        this.faq16Title = res.data.faq.faq16Title;
        this.faq16Description = res.data.faq.faq16Description;
        this.faq17Title = res.data.faq.faq17Title;
        this.faq17Description = res.data.faq.faq17Description;
        this.faq18Title = res.data.faq.faq18Title;
        this.faq18Description = res.data.faq.faq18Description;
        this.faq19Title = res.data.faq.faq19Title;
        this.faq19Description = res.data.faq.faq19Description;
        this.formTitle = res.data.form.formTitle;
        this.formText = res.data.form.formText;
        this.formCallout = res.data.form.formCallout;
        this.formInfo = res.data.form.formInfo;
        this.formButton = res.data.form.formButton;
        this.formContactInfo1 = res.data.form.formContactInfo1;
        this.formContactInfo2 = res.data.form.formContactInfo2;
        this.formContactInfo3 = res.data.form.formContactInfo3;
        this.navItems[0].label = res.data.navigation.navigationElement1;
        this.navItems[1].label = res.data.navigation.navigationElement2;
        this.navItems[2].label = res.data.navigation.navigationElement3;
        this.navItems[3].label = res.data.navigation.navigationElement4;
        this.navItems[4].label = res.data.navigation.navigationElement5;
        this.navItems[5].label = res.data.navigation.navigationElement6;
        this.footerText1 = res.data.footer.footerText1;
        this.footerText2 = res.data.footer.footerText2;
        this.footerText3 = res.data.footer.footerText3;
        this.footerText4 = res.data.footer.footerText4;
        this.carousel2 = res.data.carousel2;

        this.isLoading = false;
      })
      .catch((error) => console.log(error));

    const debounce = (fn) => {
      let frame;
      return (...params) => {
        if (frame) cancelAnimationFrame(frame);
        frame = requestAnimationFrame(() => fn(...params));
      };
    };
    const storeScroll = () => {
      this.isOnTop = window.scrollY <= this.navTransition;
    };

    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });
    storeScroll();
  },
  components: {
    NavigationComponent,
    Footer,
    Navbar,
  },
};
</script>

<style lang="scss">
@import "src/styles/main";

// #app {
//   display: flex;
//   flex-direction: column;
//   // min-height: 100vh;
// }

#app {
  min-height: 100%;
  position: relative;
}

.elder__navigation-component-icon-right {
  color: white;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  position: fixed;
  background: transparent;
  box-shadow: none;

  @media (max-width: $tablet) {
    background-color: var(--secondary);
  }

  top: 0;
  width: 100%;
  z-index: 1001;

  transition: color 0.4s ease-in-out, height 0.4s ease-in-out,
    background 0.4s ease-in-out, backdrop-filter 0.4s ease-in-out,
    -webkit-backdrop-filter 0.4s ease-in-out, transform 0.4s ease-in-out,
    -webkit-transform 0.4s ease-in-out;

  &--hero {
    .page--is-top & {
      @media (max-width: $tablet) {
        position: fixed;
        background: var(--secondary);
      }

      &:not(.nav--expanded) {
        .elder__navigation-component {
          color: var(--secondary) !important;

          @media (max-width: $tablet) {
            color: var(--primary) !important;
          }
        }
      }
    }
  }

  .container {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 9px;
    padding-bottom: 9px;

    span {
      color: var(--primary-contrast);
    }

    @media (max-width: $tablet) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: $mobile) {
      padding-left: 0px;
      padding-right: 50px;
    }
  }

  .elder__navigation-logo-image {
    width: 120px;

    @media (max-width: $mobile) {
      width: 80px;
      height: 100px;
      margin-left: 20px;
    }
  }

  .page--is-scrolled & {
    position: fixed;
    background-color: var(--secondary);
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);

    .elder__navigation-logo-image {
      transition: all 400ms ease-out;

      @media (min-width: $tablet) {
        max-height: 60px !important;
      }

      @media (max-width: $tablet) {
        max-height: 70px !important;
      }
    }

    .elder__navigation-bars {
      @media (max-width: $mobile) {
        scale: 1;
        transition: all 400ms ease-out;
      }
    }
  }

  .elder__navigation-bars {
    color: var(--primary);
    scale: 1.7;
  }

  .elder__navigation {
    button {
      margin: 0;
    }

    &-logo {
      &-image {
        transition: all 400ms ease-out;

        @media (min-width: $mobile) {
          max-width: 150px;
          max-height: 7rem !important;
        }

        @media (max-width: $mobile) {
          max-height: 110px !important;
        }
      }

      .elder__navigation-component {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-actions {
      .elder__navigation-component {
        color: var(--primary);

        &:hover {
          cursor: pointer;
          background: none;

          &:after {
            content: "";
            background-color: var(--primary);
            width: calc(100% - 2rem);
            height: 2px;
            position: absolute;
            left: 1rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    &--responsive {
      overflow-y: initial;
    }
  }

  &--expanded {
    height: 100vh;
    background-color: var(--secondary);

    .elder__navigation-actions {
      padding-left: 2.5rem;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      span {
        font-size: 1.2rem;
      }

      .elder__navigation-node {
        // padding: 0.5rem 2rem;
      }
    }
  }

  .elder__navigation-actions > :nth-child(5) {
    background-color: var(--primary);
    border-radius: 20px;

    span {
      color: var(--secondary-contrast);
    }

    // span {
    //   font-size: 1.5rem;
    //   font-family: var(--primary-font);
    //   color: var(--secondary-contrast);
    // }
  }
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 300ms ease;
}
</style>
